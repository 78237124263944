.no-animations aio-doc-viewer > * {
  // Disable view transition animations.
  transition: none !important;
}

.video-container {
  position: relative;
}

video {
  width: 100%;
  height: auto;
}
