.debug {
  [translation-result], code-tabs, code-example, img, code, pre, .filetree {
    display: none;
  }
}

[translation-result] + [translation-origin=off] {
  display: none;
}

[translation-result] + aio-toc + [translation-origin=off] {
  display: none;
}

[translation-origin=on] {
  border-top: 1px dashed #0273D4;
}

[id^=translations-cn] {
  aio-doc-viewer {
    blockquote {
      color: #455A64;
      margin: 0 0 32px 0;
      padding: 16px 32px;
      background: rgba(236, 239, 241, 0.24);
      border-left: 4px solid #00BCD4;
    }
  }
}

em {
  font-style: normal;
  font-weight: bold;
}

nt-wrapper {
  display: block;
}
